import { BASEURL, checktoken, headers } from '../../utils/helper'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from './other.slice';
import axios from 'axios';

const initialState = {
    data: [],
    total: 0,
    pages: 0,
    current: 0,
    error: null,
    loading: false,
}

export const AdminDeliveryReq = createAsyncThunk('AdminDeliveryReq', async ({ page }, { rejectWithValue, dispatch }) => {
    try {
        const response = await axios.get(`${BASEURL}/admin/deliveries/list?page=${page}`,
            { headers: headers() })
            .then((res) => {
                return res.data;
            }).catch((err) => {
                checktoken(err)
                if (err?.response?.data?.error) {
                    dispatch(toastError(err?.response?.data?.error))
                }
                return rejectWithValue(err);
            })
        return response;
    } catch (error) {
        checktoken(error)
        return error;
    }
})

export const SingleDeliveryReq = createAsyncThunk('SingleDeliveryReq', async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        const response = await axios.get(`${BASEURL}/admin/delivery/${id}`,
            { headers: headers() })
            .then((res) => {
                return res.data;
            }).catch((err) => {
                checktoken(err)
                if (err?.response?.data?.error) {
                    dispatch(toastError(err?.response?.data?.error))
                }
                return rejectWithValue(err);
            })
        return response;
    } catch (error) {
        checktoken(error)
        return error;
    }
})

export const DeliverySlice = createSlice({
    name: 'deliverySlice',
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(AdminDeliveryReq.pending, (state, action) => {
            state.loading = true
            state.total = 0
            state.pages = 0
            state.data = []
            state.current = 0
            return state
        })
        builder.addCase(AdminDeliveryReq.fulfilled, (state, action) => {
            state.loading = false
            state.total = action.payload.total
            state.pages = action.payload.pages
            state.current = action.payload.current
            state.data = action.payload.data
            return state
        })
        builder.addCase(AdminDeliveryReq.rejected, (state, action) => {
            state.loading = false
            state.total = 0
            state.pages = 0
            state.data = []
            state.current = 0
            state.error = 'Error occured'
        })
        builder.addCase(SingleDeliveryReq.pending, (state, action) => {
            state.loading = true
            return state
        })
        builder.addCase(SingleDeliveryReq.fulfilled, (state, action) => {
            state.loading = false
            return state
        })
        builder.addCase(SingleDeliveryReq.rejected, (state, action) => {
            state.loading = false
            state.error = 'Error occured'
        })
        builder.addCase('LOGOUT', () => initialState)
    }
})

export default DeliverySlice.reducer