import ForgotPass from "../images/login-images/forgot-password-frent-img.jpg"
import profile from "../images/avatars/avatar-2.png"
import favicon from "../images/favicon.png"
import Lock from "../images/icons/lock.png"
import logo from "../images/logo-icon.png"
import MainLogo from "../images/logo.png"

const Images = {
    logo,
    Lock,
    profile,
    favicon,
    MainLogo,
    ForgotPass,
}

export default Images