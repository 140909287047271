import { isSide } from '../../redux/slices/admin.slice';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useEffect, useState } from 'react';
import { Link, NavLink, } from 'react-router-dom';
import Images from '../../assets/js/images';
import { Nav } from 'react-bootstrap';

const Sidebar = ({ user }) => {
  const dispatch = useDispatch()
  const [users, setUsers] = useState(false);
  const [system, setSystem] = useState(false);
  const [support, setSupport] = useState(false);
  const [finance, setFinance] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const [reporting, setReporting] = useState(false);
  const [integration, setIntegration] = useState(false);
  const { sidebar } = useSelector(state => state.AdminSlice);

  useEffect(() => {

  }, [sidebar])

  return (
    <Fragment>
      <div className="sidebar-wrapper" data-simplebar="true">
        <div className="sidebar-header">
          <div>
            <img src={Images?.favicon} className="logo-icon" alt="logo icon" />
          </div>
          <div>
            <h4 className="logo-text">Synadmin</h4>
          </div>
          <div className="toggle-icon ms-auto" onClick={() => { dispatch(isSide(!sidebar)) }}><i className='bx bx-first-page'></i>
          </div>
        </div>
        <Nav as="ul" className="metismenu" id="menu">
          <Nav.Item as="li">
            <NavLink to="/admin" className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "mm-active" : ""
            }>
              <div className="parent-icon"><i className='bx bx-home'></i>
              </div>
              <div className="menu-title">Dashboard</div>
            </NavLink>
          </Nav.Item>
          {/* <Nav.Item as="li" className={`${integration && 'mm-active'}`}>
            <a type="button" className="has-arrow" onClick={() => setIntegration(!integration)} aria-expanded={integration}>
              <div className="parent-icon"><i className='bx bx-cog'></i>
              </div>
              <div className="menu-title">integration</div>
            </a>
            <Nav as="ul" className={`flex-column mm-collapse ${integration && 'mm-show'}`} style={{ height: `${!integration ? '2px' : 'max-content'}` }}>
              <Nav.Item as="li"> <Link to="/admin"><i className="bx bx-right-arrow-alt"></i>Business Registration</Link>
              </Nav.Item>
              <Nav.Item as="li"> <Link to="/admin/products"><i className="bx bx-right-arrow-alt"></i>Product Listing</Link>
              </Nav.Item>
              <Nav.Item as="li"> <Link to="/admin/driver"><i className="bx bx-right-arrow-alt"></i>Order Management</Link>
              </Nav.Item>
            </Nav>
          </Nav.Item> */}
          {(user?.role === 'superadmin') &&
            <>
              <Nav.Item as="li" className={`${users && 'mm-active'}`}>
                <a type="button" className="has-arrow" onClick={() => setUsers(!users)} aria-expanded={users}>
                  <div className="parent-icon"><i className='bx bx-user'></i>
                  </div>
                  <div className="menu-title">Users Management</div>
                </a>
                <Nav as="ul" className={`flex-column mm-collapse ${users && 'mm-show'}`} style={{ height: `${!users ? '2px' : 'max-content'}` }}>
                  <Nav.Item as="li"> <Link to={`/admin/${'rider'}/list`}><i className="bx bx-right-arrow-alt"></i>Rider</Link>
                  </Nav.Item>
                  <Nav.Item as="li"> <Link to={`/admin/${'driver'}/list`}><i className="bx bx-right-arrow-alt"></i>Driver</Link>
                  </Nav.Item>
                </Nav>
              </Nav.Item>
              <Nav.Item as="li" className={`${delivery && 'mm-active'}`}>
                <a type="button" className="has-arrow" onClick={() => setDelivery(!delivery)} aria-expanded={delivery}>
                  <div className="parent-icon"><i className='bx bxs-truck'></i>
                  </div>
                  <div className="menu-title">Ride and Delivery</div>
                </a>
                <Nav as="ul" className={`flex-column mm-collapse ${delivery && 'mm-show'}`} style={{ height: `${!delivery ? '2px' : 'max-content'}` }}>
                  <Nav.Item as="li"> <Link to="/admin/ride/list"><i className="bx bx-right-arrow-alt"></i>Rides</Link>
                  </Nav.Item>
                  <Nav.Item as="li"> <Link to="/admin/ride-price"><i className="bx bx-right-arrow-alt"></i>Ride/Deliveries Price</Link>
                  </Nav.Item>
                  <Nav.Item as="li"> <Link to="/admin/delivery/list"><i className="bx bx-right-arrow-alt"></i>Deliveries</Link>
                  </Nav.Item>
                  <Nav.Item as="li"> <Link to="/admin"><i className="bx bx-right-arrow-alt"></i>Dispute Resolution</Link>
                  </Nav.Item>
                </Nav>
              </Nav.Item>
              {/* <Nav.Item as="li" className={`${finance && 'mm-active'}`}>
                <a type="button" className="has-arrow" onClick={() => setFinance(!finance)} aria-expanded={finance}>
                  <div className="parent-icon"><i className='bx bxs-badge-dollar'></i>
                  </div>
                  <div className="menu-title">Financial Management</div>
                </a>
                <Nav as="ul" className={`flex-column mm-collapse ${finance && 'mm-show'}`} style={{ height: `${!finance ? '2px' : 'max-content'}` }}>
                  <Nav.Item as="li"> <Link to="/admin"><i className="bx bx-right-arrow-alt"></i>Subscription Plans</Link>
                  </Nav.Item>
                  <Nav.Item as="li"> <Link to="/admin"><i className="bx bx-right-arrow-alt"></i>Transaction History</Link>
                  </Nav.Item>
                  <Nav.Item as="li"> <Link to="/admin"><i className="bx bx-right-arrow-alt"></i>Driver Payments</Link>
                  </Nav.Item>
                </Nav>
              </Nav.Item>
              <Nav.Item as="li" className={`${reporting && 'mm-active'}`}>
                <a type="button" className="has-arrow" onClick={() => setReporting(!reporting)} aria-expanded={reporting}>
                  <div className="parent-icon"><i className='bx bxs-file'></i>
                  </div>
                  <div className="menu-title">Reporting and Analytics</div>
                </a>
                <Nav as="ul" className={`flex-column mm-collapse ${reporting && 'mm-show'}`} style={{ height: `${!reporting ? '2px' : 'max-content'}` }}>
                  <Nav.Item as="li"> <Link to="/admin"><i className="bx bx-right-arrow-alt"></i>Usage Reports</Link>
                  </Nav.Item>
                  <Nav.Item as="li"> <Link to="/admin"><i className="bx bx-right-arrow-alt"></i>Financial Reports</Link>
                  </Nav.Item>
                  <Nav.Item as="li"> <Link to="/admin"><i className="bx bx-right-arrow-alt"></i>Performance Metrics</Link>
                  </Nav.Item>
                </Nav>
              </Nav.Item>
              <Nav.Item as="li" className={`${support && 'mm-active'}`}>
                <a type="button" className="has-arrow" onClick={() => setSupport(!support)} aria-expanded={support}>
                  <div className="parent-icon"><i className='bx bx-headphone'></i>
                  </div>
                  <div className="menu-title">Customer Support</div>
                </a>
                <Nav as="ul" className={`flex-column mm-collapse ${support && 'mm-show'}`} style={{ height: `${!support ? '2px' : 'max-content'}` }}>
                  <Nav.Item as="li"> <Link to="/admin"><i className="bx bx-right-arrow-alt"></i>Support Tickets</Link>
                  </Nav.Item>
                  <Nav.Item as="li"> <Link to="/admin"><i className="bx bx-right-arrow-alt"></i>Feedback Management</Link>
                  </Nav.Item>
                </Nav>
              </Nav.Item>
              <Nav.Item as="li" className={`${system && 'mm-active'}`}>
                <a type="button" className="has-arrow" onClick={() => setSystem(!system)} aria-expanded={system}>
                  <div className="parent-icon"><i className='bx bxs-cog'></i>
                  </div>
                  <div className="menu-title">System Administration</div>
                </a>
                <Nav as="ul" className={`flex-column mm-collapse ${system && 'mm-show'}`} style={{ height: `${!system ? '2px' : 'max-content'}` }}>
                  <Nav.Item as="li"> <Link to="/admin"><i className="bx bx-right-arrow-alt"></i>Access Control</Link>
                  </Nav.Item>
                  <Nav.Item as="li"> <Link to="/admin"><i className="bx bx-right-arrow-alt"></i>System Settings</Link>
                  </Nav.Item>
                </Nav>
              </Nav.Item> */}
            </>
          }
        </Nav>
      </div>
    </Fragment >
  )
}

export default Sidebar;