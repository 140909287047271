import { AdminResetPassReq } from '../../redux/slices/admin.slice'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Toaster from '../../components/common/Toaster'
import { Fragment, useEffect, useState } from 'react'
import { Card, Form, Row } from 'react-bootstrap'
import Images from '../../assets/js/images'
import { useDispatch } from 'react-redux'

const ResetPassword = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [params] = useSearchParams()
    const [token, setToken] = useState('')


    const handleSubmit = (e) => {
        e.preventDefault()
        const form = new FormData(e.target)
        const formData = Object.fromEntries(form)
        dispatch(AdminResetPassReq({ data: formData, navigate }))
    }

    useEffect(() => {
        params.get('token') ? setToken(params.get('token')) : setToken('');
        // eslint-disable-next-line
    }, [])

    return (
        <Fragment>
            <div className="authentication-header"></div>
            <div className="authentication-reset-password d-flex align-items-center justify-content-center">
                <Row className="w-100">
                    <div className="col-10 col-lg-10 mx-auto">
                        <Card className="card">
                            <div className="row g-0">
                                <div className="col-lg-5 border-end">
                                    <div className="card-body">
                                        <div className="p-5">
                                            <div className="text-start">
                                                <img src={Images?.MainLogo} width="180" alt="" />
                                            </div>
                                            <h4 className="mt-5 font-weight-bold">Genrate New Password</h4>
                                            <p className="text-muted">We received your reset password request. Please enter your new password!</p>
                                            <Form onSubmit={handleSubmit}>
                                                <div className="mb-3 mt-5">
                                                    <label className="form-label">New Password</label>
                                                    <Form.Control type="text" name="password" placeholder="Enter new password" />
                                                    <Form.Control type="hidden" name="token" value={token} />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Confirm Password</label>
                                                    <Form.Control type="text" name="confirmpassword" placeholder="Confirm password" />
                                                </div>
                                                <div className="d-grid gap-2">
                                                    <button type="submit" className="btn btn-primary">Change Password</button> <Link to="/login" className="btn btn-light"><i className='bx bx-arrow-back mr-1'></i>Back to Login</Link>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <img src={Images?.ForgotPass} className="card-img login-img h-100" alt="..." />
                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>
            </div>
        </Fragment>
    )
}

export default ResetPassword
