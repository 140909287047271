import { BASEURL, checktoken, headers } from '../../utils/helper'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError, toastSuccess } from './other.slice';
import { addAuth } from './auth.slice';
import { Cookies } from 'react-cookie';
import axios from 'axios';


const initialState = {
    data: {},
    sidebar: true,
    error: null,
    loading: false,
}

export const AdminProfileUpdateReq = createAsyncThunk('AdminProfileUpdateReq', async ({ data, id }, { rejectWithValue, dispatch }) => {
    try {
        const cookies = new Cookies()
        const response = await axios.put(`${BASEURL}/admin/update/profile/${id}`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${cookies.get('auth')}`,
                }
            })
            .then((res) => {
                if (res?.data?.success) {
                    console.log(res?.data?.data)
                    dispatch(addAuth(res?.data?.data))
                    dispatch(toastSuccess(res?.data?.message))
                }
                return res.data;
            }).catch((err) => {
                if (err?.response?.data?.error) {
                    dispatch(toastError(err?.response?.data?.error))
                }
                return rejectWithValue(err);
            })
        return response;
    } catch (error) {
        checktoken(error)
        return error;
    }
})

export const AdminForgotPassReq = createAsyncThunk('AdminForgotPassReq', async ({ data }, { rejectWithValue, dispatch }) => {
    try {
        const response = await axios.post(`${BASEURL}/admin/send/forgot-password/link`, data,
            { headers: headers() })
            .then((res) => {
                if (res?.data?.success) {
                    dispatch(toastSuccess(res?.data?.message))
                }
                return res.data;
            }).catch((err) => {
                if (err?.response?.data?.error) {
                    dispatch(toastError(err?.response?.data?.error))
                }
                return rejectWithValue(err);
            })
        return response;
    } catch (error) {
        checktoken(error)
        return error;
    }
})

export const AdminResetPassReq = createAsyncThunk('AdminResetPassReq', async ({ data, navigate }, { rejectWithValue, dispatch }) => {
    try {
        const response = await axios.post(`${BASEURL}/admin/reset-password`, data,
            { headers: headers() })
            .then((res) => {
                if (res?.data?.success) {
                    navigate('/login')
                    // dispatch(toastSuccess(res?.data?.message))
                }
                return res.data;
            }).catch((err) => {
                console.log(err?.response?.data?.error)
                if (err?.response?.data?.error) {
                    dispatch(toastError(err?.response?.data?.error))
                }
                return rejectWithValue(err);
            })
        return response;
    } catch (error) {
        checktoken(error)
        return error;
    }
})

export const AdminSlice = createSlice({
    name: 'adminSlice',
    initialState: initialState,
    reducers: {
        isSide: (state, action) => {
            state.sidebar = action.payload
            return state
        },
    },
    extraReducers: (builder) => {
        builder.addCase(AdminProfileUpdateReq.pending, (state, action) => {
            state.loading = true
            state.data = {}
            return state
        })
        builder.addCase(AdminProfileUpdateReq.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload.data
            return state
        })
        builder.addCase(AdminProfileUpdateReq.rejected, (state, action) => {
            state.loading = false
            state.data = {}
            state.error = 'Error occured'
        })
    }
})

export const { isSide } = AdminSlice.actions
export default AdminSlice.reducer