import { lazy } from 'react';
const SingleDelivery = lazy(() => import('../pages/admin/delivery/SingleDelivery'));
const ProductList = lazy(() => import('../pages/admin/product/ProductList'));
const UserProfile = lazy(() => import('../pages/admin/users/UserProfile'));
const SingleRide = lazy(() => import('../pages/admin/rides/SingleRide'));
const Delivery = lazy(() => import('../pages/admin/delivery/Delivery'));
const RidePrice = lazy(() => import('../pages/admin/rides/RidePrice'));
const Dashboard = lazy(() => import('../pages/admin/Dashboard'));
const User = lazy(() => import('../pages/admin/users/User'));
const Ride = lazy(() => import('../pages/admin/rides/Ride'));
const Profile = lazy(() => import('../pages/admin/Profile'));

const AdminRoute = [
    { path: '/admin', name: 'Dashboard', component: <Dashboard /> },
    { path: '/admin/profile', name: 'Profile', component: <Profile /> },
    { path: '/admin/ride/list/:page?', name: 'Ride', component: <Ride /> },
    { path: '/admin/:type/list/:page?', name: 'User', component: <User /> },
    { path: '/admin/ride/:id', name: 'SingleRide', component: <SingleRide /> },
    { path: '/admin/products', name: 'ProductList', component: <ProductList /> },
    { path: '/admin/delivery/list/:page?', name: 'Delive', component: <Delivery /> },
    { path: '/admin/:type/profile/:id', name: 'Profile', component: <UserProfile /> },
    { path: '/admin/delivery/:id', name: 'SDelivery', component: <SingleDelivery /> },
    { path: '/admin/ride-price', name: 'RidePrice', component: <RidePrice /> },
];

export default AdminRoute;