import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import FeaturedVideoRoundedIcon from '@mui/icons-material/FeaturedVideoRounded';
import VerticalSplitRoundedIcon from '@mui/icons-material/VerticalSplitRounded';
import SubscriptionsRoundedIcon from '@mui/icons-material/SubscriptionsRounded';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import TroubleshootRoundedIcon from '@mui/icons-material/TroubleshootRounded';
import ContactPageRoundedIcon from '@mui/icons-material/ContactPageRounded';
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MovieRoundedIcon from '@mui/icons-material/MovieRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FacebookIcon from '@mui/icons-material/Facebook';
import TerminalIcon from '@mui/icons-material/Terminal';
import InsightsIcon from '@mui/icons-material/Insights';
import { BsCalendar2DateFill } from "react-icons/bs";
import SchoolIcon from '@mui/icons-material/School';
import { TbGiftFilled } from "react-icons/tb";
import {
  FaLinkedinIn, FaInstagram,
  FaFacebookF, FaTwitter,
} from "react-icons/fa";

export const icons = {
  // Mui-Icons
  MuiMenu: MenuRoundedIcon,
  MuiTimelapse: TimelapseIcon,
  MuiSubscriptions: SubscriptionsRoundedIcon,
  MuiSchool: <SchoolIcon />,
  MuiLogout: LogoutRoundedIcon,
  MuiTerminal: <TerminalIcon />,
  Insights: <InsightsIcon />,
  MuiAccount: AccountCircleIcon,
  MuiTaskAlt: TaskAltRoundedIcon,
  MuiClose: <CloseRoundedIcon />,
  MuiAddCircleIcon: AddCircleIcon,
  MuiStarRate: StarRateRoundedIcon,
  MuiWatchLater: WatchLaterRoundedIcon,
  MuiFacebook: <FacebookIcon />,
  MuiMedia: <MovieRoundedIcon />,
  MuiGrid: <GridViewRoundedIcon />,
  MuiPlay: <PlayArrowRoundedIcon />,
  MuiHistory: <HistoryRoundedIcon />,
  MuiArticle: <ArticleRoundedIcon />,
  MuiAuthor: <ContactPageRoundedIcon />,
  MuiUsers: <PeopleOutlineRoundedIcon />,
  MuiDownArrow_1: ArrowDropDownRoundedIcon,
  MuiResearch: <TroubleshootRoundedIcon />,
  MuiCategory: <VerticalSplitRoundedIcon />,
  MuiFeatured: <FeaturedVideoRoundedIcon />,
  MuiAddPerson: <PersonAddAlt1RoundedIcon />,
  MuiUpArrow: <KeyboardArrowUpRoundedIcon />,
  MuiAdmin: <AdminPanelSettingsRoundedIcon />,
  MuiSlowMotionVideo: <SlowMotionVideoIcon />,
  MuiDocumentScanner: DocumentScannerIcon,
  MuiDownArrow: <KeyboardArrowDownRoundedIcon />,
  MuiTransaction: <AccountBalanceWalletRoundedIcon />,

  // Other
  TwitterIcon_1: <FaTwitter className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" />,
  FacebookIcon_1: <FaFacebookF className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" />,
  InstagramIcon_1: <FaInstagram className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" />,
  LinkedinInIcon_1: <FaLinkedinIn className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" />,
  GiftIcon_1: TbGiftFilled,
  DateIcon_1: BsCalendar2DateFill,
  MusicNoteIcon: <InsightsIcon />,
}