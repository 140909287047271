import { AdminForgotPassReq } from '../../redux/slices/admin.slice'
import Toaster from '../../components/common/Toaster'
import { Card, Form, Button } from 'react-bootstrap'
import Images from '../../assets/js/images'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import React, { Fragment } from 'react'

const ForgotPassword = () => {
    const dispatch = useDispatch()

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = new FormData(e.target)
        const formData = Object.fromEntries(form)
        dispatch(AdminForgotPassReq({ data: formData }))
    }

    return (
        <Fragment>
            <div className="authentication-header"></div>
            <div className="authentication-forgot d-flex align-items-center justify-content-center">
                <Card className="forgot-box rounded-4">
                    <Card.Body>
                        <div className="p-4 rounded">
                            <div className="text-center">
                                <img src={Images?.Lock} width="120" alt="" />
                            </div>
                            <h4 className="mt-5 font-weight-bold">Forgot Password?</h4>
                            <p className="text-muted">Enter your registered email ID to reset the password</p>
                            <Form onSubmit={handleSubmit}>
                                <div className="my-4">
                                    <label className="form-label">Email id</label>
                                    <Form.Control type="text" name="email" className="form-control-lg" placeholder="example@user.com" />
                                </div>
                                <div className="d-grid gap-2">
                                    <Button type="submit" className="btn btn-primary btn-lg">Send</Button> <Link to="/login" className="btn btn-white btn-lg"><i className='bx bx-arrow-back me-1'></i>Back to Login</Link>
                                </div>
                            </Form>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </Fragment>
    )
}

export default ForgotPassword
