import { toastHide } from "../../redux/slices/other.slice"
import { Alert, Slide, Snackbar } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"

const SlideComponet = (props) => (
    <Slide {...props} direction="left" />
)

function Toaster() {
    const dispatch = useDispatch()
    const { toast } = useSelector(state => state.otherSlice)

    const handleClose = () => {
        dispatch(toastHide())
    }

    return (
        <Snackbar
            open={toast?.show}
            onClose={handleClose}
            autoHideDuration={1200}
            TransitionComponent={SlideComponet}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
            <Alert
                onClose={handleClose}
                severity={toast?.severity}
            >{toast?.msg}
            </Alert>
        </Snackbar>
    )
}

export default Toaster