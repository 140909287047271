import RingLoader from "react-spinners/RingLoader";

const Loader = () => {
    return (
        <div className="position-fixed top-0 bottom-0 start-0 end-0 d-flex align-items-center justify-content-center" style={{zIndex:99999}}>
            <RingLoader 
                color={`#8833ff`}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>
    )
}

export default Loader;