import DashboardSlice from "../slices/dashboard.slice";
import DeliverySlice from "../slices/delivery.slice";
import { combineReducers } from "@reduxjs/toolkit";
import MessageSlice from "../slices/message.slice";
import AdminSlice from "../slices/admin.slice";
import UsersSlice from "../slices/users.slice";
import otherSlice from "../slices/other.slice";
import RideSlice from "../slices/rides.slice";
import AuthSlice from "../slices/auth.slice";


const rootReducer = combineReducers({
  RideSlice,
  AuthSlice,
  otherSlice,
  UsersSlice,
  AdminSlice,
  MessageSlice,
  DeliverySlice,
  DashboardSlice
})

export default rootReducer