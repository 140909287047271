import { useTranslation } from "react-i18next"

function Footer() {
    const date = new Date()
    const { t } = useTranslation()
    const year = date.getFullYear()

    return (
        <div id="Footer">
            <footer className="primary-bg text-white">
                <div className="py-3 text-center border-top">
                    <p className="text-white mb-0">{t('f_text')}</p>
                </div>
            </footer>
        </div>
    )
}

export default Footer
