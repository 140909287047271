import ButtonSpinner from '../../components/common/ButtonSpinner'
import { Row, Col, Form, Button, Card } from 'react-bootstrap'
import { AuthRequest } from '../../redux/slices/auth.slice'
import { useDispatch, useSelector } from 'react-redux'
import Toaster from '../../components/common/Toaster'
import { Link, useNavigate } from 'react-router-dom'
import { Fragment, useState } from 'react'

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { data: auth, loading } = useSelector(state => state.AuthSlice)
  // eslint-disable-next-line

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form)
    dispatch(AuthRequest({ data: formData, navigate }))
  }

  return (
    <Fragment>
      <div className="wrapper">
        <div className="authentication-header"></div>
        <div className="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
          <div className="container">
            <Row className="row-cols-1 row-cols-lg-2 row-cols-xl-3">
              <Col className="col mx-auto">
                <div className="mb-4 text-center">
                  <img src="assets/images/logo-img.png" width="180" alt="" />
                </div>
                <Card className="card rounded-4">
                  <div className="card-body">
                    <div className="p-4 rounded">
                      <div className="text-center">
                        <h3 className="">Sign in</h3>
                        {/* <p>Don't have an account yet? <a href="authentication-signup.html">Sign up here</a>
                        </p> */}
                      </div>
                      <div className="form-body">
                        <Form className="row g-3" onSubmit={handleSubmit}>
                          <Col className="col-12">
                            <Form.Label htmlFor="inputEmailAddress" className="form-label">Email Address</Form.Label>
                            <Form.Control type="email" name="email" id="inputEmailAddress" placeholder="Email Address" />
                          </Col>
                          <Col className="col-12">
                            <Form.Label htmlFor="inputChoosePassword" className="form-label">Enter Password</Form.Label>
                            <div className="input-group" id="show_hide_password">
                              <Form.Control type={showPassword ? 'text' : 'password'} name="password" className="border-end-0" id="inputChoosePassword" placeholder="Enter Password" /> <a type="button" onClick={() => { setShowPassword(!showPassword) }} className="input-group-text bg-transparent">{showPassword ? <i className='bx bx-show'></i> : <i className='bx bx-hide'></i>}</a>
                            </div>
                          </Col>
                          {/* <Col md={6}>
                            <div className="form-check form-switch">
                              <Form.Control className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" defaultChecked />
                              <Form.Label className="form-check-label" htmlFor="flexSwitchCheckChecked">Remember Me</Form.Label>
                            </div>
                          </Col> */}
                          <Col md={6} className="text-end">	<Link to="/forgot-password">Forgot Password ?</Link>
                          </Col>
                          <Col className="col-12">
                            <div className="d-grid">
                              <Button type="submit" className="btn btn-primary d-flex align-items-center justify-content-center"><i className="bx bxs-lock-open"></i>Sign in {loading && <ButtonSpinner />}</Button>
                            </div>
                          </Col>
                        </Form>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Login
