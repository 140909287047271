import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  toast: {}
}
const other = createSlice({
  name: "other",
  initialState,
  reducers: {
    toastError: (state, { payload }) => {
      state.toast.show = true
      state.toast.msg = payload
      state.toast.severity = "error"
    },
    toastSuccess: (state, { payload }) => {
      state.toast.show = true
      state.toast.msg = payload
      state.toast.severity = "success"
    },
    toastHide: () => (initialState)
  },
  extraReducers: (builder) => {
    builder.addCase('LOGOUT', () => initialState)
  }
})

export const { toastError, toastHide, toastSuccess } = other.actions
export default other.reducer