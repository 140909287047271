import { BASEURL, checktoken, headers } from '../../utils/helper'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from './other.slice';
import axios from 'axios';

export const DashboardReq = createAsyncThunk('DashboardReq', async ({ rejectWithValue, dispatch }) => {
    try {
        const response = await axios.get(`${BASEURL}/admin/dashboard`,
            { headers: headers() })
            .then((res) => {
                return res.data;
            }).catch((err) => {
                checktoken(err)
                if (err?.response?.data?.error) {
                    dispatch(toastError(err?.response?.data?.error))
                }
                return rejectWithValue(err);
            })
        return response;
    } catch (error) {
        checktoken(error)
        if (error?.response?.data?.error) {
            dispatch(toastError(error?.response?.data?.error))
        }
        return error;
    }
})


const initialState = {
    data: {},
    error: null,
    loading: false,
}

export const DashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(DashboardReq.pending, (state, action) => {
            state.loading = true
            state.data = {}
            return state
        })
        builder.addCase(DashboardReq.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload.data
            return state
        })
        builder.addCase(DashboardReq.rejected, (state, action) => {
            state.loading = false
            state.data = {}
            state.error = 'Error occured'
        })
        builder.addCase('LOGOUT', () => initialState)
    }
})

export default DashboardSlice.reducer
