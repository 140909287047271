import { Cookies } from 'react-cookie';
import axios from 'axios';
const cookies = new Cookies();
export const UPLOAD_CKEDITOR_URL = `${process.env.REACT_APP_API_URL}/admin/file/uploader`;
export const BASEURL = process.env.REACT_APP_API_URL;

export const isUserLogin = Boolean(cookies.get("auth"))

export function headers() {
    return {
        'withCredentials': true,
        'credentials': 'include',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookies.get('auth')}`,
    }
}

export function checktoken(err) {
    if (err?.response) {
        if (err.response?.data?.redirect) {
            localStorage.removeItem('persist:root')
            cookies.remove('auth');
            window.location.assign(err.response.data.location);
        }
    }
}

export function dateConvert(dateString) {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-US', options).replace(',', '');
}

export const languages = [
    { value: 'Albanian', code: 'al' },
    { value: 'Arabic', code: 'ar' },
    { value: 'Armenian', code: 'ar' },
    { value: 'Awadhi', code: 'ar' },
    { value: 'Azerbaijani', code: 'ar' },
    { value: 'Bashkir', code: 'ar' },
    { value: 'Basque', code: 'ar' },
    { value: 'Belarusian', code: 'ar' },
    { value: 'Bengali', code: 'ar' },
    { value: 'Bhojpuri', code: 'ar' },
    { value: 'Bosnian', code: 'ar' },
    { value: 'Brazilian Portuguese', code: 'ar' },
    { value: 'Bulgarian', code: 'ar' },
    { value: 'Cantonese', code: 'ar' },
    { value: 'Chhattisgarhi', code: 'ar' },
    { value: 'Chinese', code: 'ar' },
    { value: 'Danish', code: 'ar' },
    { value: 'Dutch', code: 'ar' },
    { value: 'English', code: 'en' },
    { value: 'Finnish', code: 'ar' },
    { value: 'French', code: 'fr' },
    { value: 'Galician', code: 'ar' },
    { value: 'Georgian', code: 'ar' },
    { value: 'German', code: 'ar' },
    { value: 'Greek', code: 'ar' },
    { value: 'Gujarati', code: 'ar' },
    { value: 'Haryanvi', code: 'ar' },
    { value: 'Hindi' },
    { value: 'Hungarian', code: 'ar' },
    { value: 'Indonesian', code: 'ar' },
    { value: 'Irish', code: 'ar' },
    { value: 'Italian', code: 'ar' },
    { value: 'Japanese', code: 'ar' },
    { value: 'Javanese', code: 'ar' },
    { value: 'Kannada', code: 'ar' },
    { value: 'Kashmiri', code: 'ar' },
    { value: 'Kazakh', code: 'ar' },
    { value: 'Konkani', code: 'ar' },
    { value: 'Korean', code: 'ar' },
    { value: 'Kyrgyz', code: 'ar' },
    { value: 'Latvian', code: 'ar' },
    { value: 'Lithuanian', code: 'ar' },
    { value: 'Macedonian', code: 'ar' },
    { value: 'Maithili', code: 'ar' },
    { value: 'Malay', code: 'ar' },
    { value: 'Maltese', code: 'ar' },
    { value: 'Mandarin', code: 'ar' },
    { value: 'Marathi', code: 'ar' },
    { value: 'Marwari', code: 'ar' },
    { value: 'Min Nan', code: 'ar' },
    { value: 'Moldovan', code: 'ar' },
    { value: 'Mongolian', code: 'ar' },
    { value: 'Montenegrin', code: 'ar' },
    { value: 'Nepali', code: 'ar' },
    { value: 'Norwegian', code: 'ar' },
    { value: 'Oriya', code: 'ar' },
    { value: 'Pashto', code: 'ar' },
    { value: 'Persian (Farsi)', code: 'ar' },
    { value: 'Polish', code: 'ar' },
    { value: 'Portuguese', code: 'ar' },
    { value: 'Punjabi', code: 'ar' },
    { value: 'Rajasthani', code: 'ar' },
    { value: 'Romanian', code: 'ar' },
    { value: 'Russian', code: 'ar' },
    { value: 'Sanskrit', code: 'ar' },
    { value: 'Santali', code: 'ar' },
    { value: 'Serbian', code: 'ar' },
    { value: 'Sindhi', code: 'ar' },
    { value: 'Sinhala', code: 'ar' },
    { value: 'Slovak', code: 'ar' },
    { value: 'Slovene', code: 'ar' },
    { value: 'Slovenian', code: 'ar' },
    { value: 'Ukrainian', code: 'ar' },
    { value: 'Urdu', code: 'ar' },
    { value: 'Uzbek', code: 'ar' },
    { value: 'Vietnamese', code: 'ar' },
    { value: 'Welsh', code: 'ar' },
    { value: 'Wu', code: 'ar' },
];

const leadingZeroFormatter = new Intl.NumberFormat(undefined, {
    minimumIntegerDigits: 2,
})

const slickSettings = {
    additionalTransfrom: 0,
    arrows: true,
    autoPlaySpeed: 3000,
    centerMode: false,
    className: "py-2",
    dotListClass: "",
    draggable: true,
    focusOnSelect: false,
    infinite: false,
    itemClass: "series-item",
    keyBoardControl: true,
    minimumTouchDrag: 80,
    pauseOnHover: true,
    renderArrowsWhenDisabled: false,
    renderButtonGroupOutside: false,
    renderDotsOutside: false,
    responsive: {
        superLargeDesktop: {
            breakpoint: {
                max: 3000,
                min: 1200
            },
            items: 4,
            slidesToSlide: 4,
            partialVisibilityGutter: 40
        },
        desktop: {
            breakpoint: {
                max: 1200,
                min: 900
            },
            items: 3,
            slidesToSlide: 3,
            partialVisibilityGutter: 40
        },
        mobile: {
            breakpoint: {
                max: 600,
                min: 0
            },
            items: 1,
            slidesToSlide: 1,
            partialVisibilityGutter: 30
        },
        tablet: {
            breakpoint: {
                max: 900,
                min: 600
            },
            items: 2,
            slidesToSlide: 2,
            partialVisibilityGutter: 30
        }
    },
    rewind: false,
    rewindWithAnimation: false,
    rtl: false,
    shouldResetAutoplay: true,
    showDots: false,
    sliderClass: "",
    slidesToSlide: 4,
    swipeable: true,
};

function checkFileType(url) {
    const fileExtension = url.split('.').pop().toLowerCase();

    if (fileExtension === 'pdf') {
        return 'pdf';
    } else if (fileExtension === 'doc' || fileExtension === 'docx') {
        return 'doc';
    }
}

export { leadingZeroFormatter, slickSettings, checkFileType }