import ForgotPass from '../pages/frontend/ForgotPassword';
import ResetPass from '../pages/frontend/ResetPassword';
// import AdminLogin from '../pages/admin/AdminLogin';
// import Signup from '../pages/frontend/Signup';
import Login from '../pages/frontend/Login';

const FrontendRoute = [
    { path: '/', name: 'Home', component: <Login /> },
    { path: '/login', name: 'Login', component: <Login /> },
    // { path: '/signup', name: 'Signup', component: <Signup /> },
    { path: '/reset-password', name: 'Signup', component: <ResetPass /> },
    { path: '/forgot-password', name: 'Signup', component: <ForgotPass /> },
    // { path: '/admin/login', name: 'AdminLogin', component: <AdminLogin /> },
];

export default FrontendRoute;