import { BASEURL, checktoken, headers } from '../../utils/helper'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError, toastSuccess } from './other.slice';
import { Cookies } from 'react-cookie';
import axios from 'axios';

export const AuthRequest = createAsyncThunk('AuthRequest', async ({ data, navigate }, { rejectWithValue, dispatch }) => {
    const cookies = new Cookies();
    try {
        const response = await axios.post(`${BASEURL}/admin/login`, data,
            { headers: headers() })
            .then((res) => {
                if (res?.data?.success) {
                    dispatch(toastSuccess(res?.data?.message))
                    cookies.set('auth', res.data.data?.token, { path: '/' });
                    navigate('/admin');
                }
                return res.data;
            }).catch((err) => {
                if (err?.response?.data?.error) {
                    dispatch(toastError(err?.response?.data?.error))
                }
                return rejectWithValue(err);
            })
        return response;
    } catch (error) {
        checktoken(error)
        return error;
    }
})

export const AuthLogout = createAsyncThunk('AuthLogout', async ({ data, navigate }, { rejectWithValue, dispatch }) => {
    const cookies = new Cookies();
    try {
        const response = await axios.post(`${BASEURL}/admin/logout`, data,
            { headers: headers() })
            .then((res) => {
                if (res?.data?.success) {
                    dispatch(toastSuccess(res?.data?.message))
                    cookies.remove('auth', { path: '/' });
                    navigate(res.data.redirect);
                }
                return res.data;
            }).catch((err) => {
                if (err?.response?.data?.error) {
                    dispatch(toastError(err?.response?.data?.error))
                }
                return rejectWithValue(err);
            })
        return response;
    } catch (error) {
        checktoken(error)
        return error;
    }
})

const initialState = {
    data: null,
    error: null,
    loading: false,
}

export const AuthSlice = createSlice({
    name: 'authUser',
    initialState: initialState,
    reducers: {
        addAuth: (state, action) => {
            state.data = action.payload
            return state
        },
        removeAuth: (state, action) => {
            state.data = action.payload
            return state
        },
    },
    extraReducers: (builder) => {
        builder.addCase(AuthRequest.pending, (state, action) => {
            state.loading = true
            state.data = null
            return state
        })
        builder.addCase(AuthRequest.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload.data?.user
            return state
        })
        builder.addCase(AuthRequest.rejected, (state, action) => {
            state.loading = false
            state.data = null
            state.error = 'Error occured'
        })
        builder.addCase(AuthLogout.pending, (state, action) => {
            state.loading = true
            state.data = null
            return state
        })
        builder.addCase(AuthLogout.fulfilled, (state, action) => {
            return initialState
        })
        builder.addCase(AuthLogout.rejected, (state, action) => {
            state.loading = false
            state.data = null
            state.error = 'Error occured'
        })
        builder.addCase('LOGOUT', () => initialState)
    }
})

export const { addAuth, removeAuth } = AuthSlice.actions
export default AuthSlice.reducer
