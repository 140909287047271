import { headers, checktoken } from '../../utils/helper'
import { addAuth } from '../../redux/slices/auth.slice'
import { Outlet, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Fragment, useEffect, useMemo } from 'react'
import { Cookies } from 'react-cookie'
import Sidebar from './Sidebar'
import Header from './Header'
import Footer from './Footer'
import axios from "axios"

const AdminLayout = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const cookies = new Cookies();
    const cookie = cookies.get('auth')
    const { sidebar } = useSelector(state => state.AdminSlice)
    const { data: user } = useSelector(state => state.AuthSlice)

    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(`${process.env.REACT_APP_API_URL}/admin/verify`, {
            headers: headers()
        }).then((res) => {
            if (res.data?.success === true) {
                dispatch(addAuth(res.data.data))
                if (res.data?.data === 'user' && (res.data?.data !== 'admin' || res.data?.data !== 'superadmin') && cookie !== null && cookie !== undefined) {
                    return true;
                } else {

                }
            }
        }).catch((err) => {
            checktoken(err);
        })

        //eslint-disable-next-line
    }, [cookie, navigate]);

    useMemo(() => {
        return sidebar
    }, [sidebar])

    return (
        <Fragment>
            <div className={`wrapper ${sidebar ? '' : ''}`} >
                <Sidebar user={user} />
                <Header user={user} />
                <div className="page-wrapper">
                    <div className="page-content">
                        <Outlet context={[user]} />
                    </div>
                </div>
                <div className="overlay toggle-icon"></div>
                <a type="button" className="back-to-top"><i className='bx bxs-up-arrow-alt'></i></a>
                <Footer />
            </div>
        </Fragment>
    )
}

export default AdminLayout;