import { BASEURL, checktoken, headers } from '../../utils/helper'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError, toastSuccess } from './other.slice';
import axios from 'axios';

const initialState = {
    data: [],
    total: 0,
    pages: 0,
    current: 0,
    user: null,
    error: null,
    loading: false,
}

export const AdminUserReq = createAsyncThunk('AdminUserReq', async ({ role, page }, { rejectWithValue, dispatch }) => {
    try {
        const response = await axios.get(`${BASEURL}/admin/users/${role}/list?page=${page}`,
            { headers: headers() })
            .then((res) => {
                return res.data;
            }).catch((err) => {
                checktoken(err)
                if (err?.response?.data?.error) {
                    dispatch(toastError(err?.response?.data?.error))
                }
                return rejectWithValue(err);
            })
        return response;
    } catch (error) {
        checktoken(error)
        return error;
    }
})

export const AdminSingleUserReq = createAsyncThunk('AdminSingleUserReq', async ({ id, role }, { rejectWithValue, dispatch }) => {
    try {
        const response = await axios.get(`${BASEURL}/admin/user/profile/${id}/${role}`,
            { headers: headers() })
            .then((res) => {
                if (res?.data?.success) {
                    // dispatch(toastSuccess(res?.data?.message))
                }
                return res.data;
            }).catch((err) => {
                checktoken(err)
                if (err?.response?.data?.error) {
                    dispatch(toastError(err?.response?.data?.error))
                }
                return rejectWithValue(err);
            })
        return response;
    } catch (error) {
        checktoken(error)
        return error;
    }
})

export const deleteUserReq = createAsyncThunk('deleteUserReq', async ({ id, role, page }, { rejectWithValue, dispatch }) => {
    try {
        const response = await axios.delete(`${BASEURL}/admin/delete/user/${id}`,
            { headers: headers() })
            .then((res) => {
                if (res?.data?.success) {
                    dispatch(toastSuccess(res?.data?.message))
                    dispatch(AdminUserReq({ role: role, page: page ? page : 1 }))
                }
                return res.data;
            }).catch((err) => {
                checktoken(err)
                if (err?.response?.data?.error) {
                    dispatch(toastError(err?.response?.data?.error))
                }
                return rejectWithValue(err);
            })
        return response;
    } catch (error) {
        checktoken(error)
        return error;
    }
})

export const AdminUserActiveDeactiveReq = createAsyncThunk('AdminUserActiveDeactiveReq', async ({ id, role, status }, { rejectWithValue, dispatch }) => {
    try {
        const response = await axios.put(`${BASEURL}/admin/user/active/deactive/${id}/${role}`, { status },
            { headers: headers() })
            .then((res) => {
                return res.data;
            }).catch((err) => {
                checktoken(err)
                if (err?.response?.data?.error) {
                    dispatch(toastError(err?.response?.data?.error))
                }
                return rejectWithValue(err);
            })
        return response;
    } catch (error) {
        checktoken(error)
        return error;
    }
})

export const UsersSlice = createSlice({
    name: 'usersSlice',
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(AdminUserReq.pending, (state, action) => {
            state.loading = true
            state.total = 0
            state.pages = 0
            state.data = []
            state.current = 0
            return state
        })
        builder.addCase(AdminUserReq.fulfilled, (state, action) => {
            state.loading = false
            state.total = action.payload.total
            state.pages = action.payload.pages
            state.current = action.payload.current
            state.data = action.payload.data
            return state
        })
        builder.addCase(AdminUserReq.rejected, (state, action) => {
            state.loading = false
            state.total = 0
            state.pages = 0
            state.data = []
            state.current = 0
            state.error = 'Error occured'
        })
        builder.addCase(AdminSingleUserReq.pending, (state, action) => {
            state.loading = true
            state.user = null
            return state
        })
        builder.addCase(AdminSingleUserReq.fulfilled, (state, action) => {
            state.loading = false
            state.user = action.payload.data
            return state
        })
        builder.addCase(AdminSingleUserReq.rejected, (state, action) => {
            state.loading = false
            state.user = null
            state.error = 'Error occured'
        })
        builder.addCase(AdminUserActiveDeactiveReq.pending, (state, action) => {
            state.user = null
            state.loading = true
            return state
        })
        builder.addCase(AdminUserActiveDeactiveReq.fulfilled, (state, action) => {
            console.log(action.payload.data)
            state.user = action.payload.data
            state.loading = false
            return state
        })
        builder.addCase(AdminUserActiveDeactiveReq.rejected, (state, action) => {
            state.user = null
            state.loading = true
            state.error = 'Error occured'
        })
        builder.addCase('LOGOUT', () => initialState)
    }
})

export default UsersSlice.reducer