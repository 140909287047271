import { toastError, toastSuccess } from "./redux/slices/other.slice";
import AdminLayout from "./components/admin/AdminLayout";
import { useDispatch, useSelector } from "react-redux";
import { Suspense, useEffect, useState } from "react";
import ProtectedRoute from './utils/ProtectedRoute';
import FrontendRoute from './router/FrontendRoute';
import Toaster from "./components/common/Toaster";
import Layout from './components/frontend/Layout';
import { Route, Routes } from "react-router-dom";
import NotFound from "./pages/frontend/NotFound";
import Loader from "./components/common/Loader";
import AdminRoute from './router/AdminRoute';
import { Cookies } from "react-cookie";
import io from 'socket.io-client'

function App() {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const [socket, setSocket] = useState(null);
  const SOCKET_SERVER_URL = "https://canws.in";//live code
  // const SOCKET_SERVER_URL = "http://localhost:1000";
  const { data: user } = useSelector(state => state.AuthSlice);

  useEffect(() => {
    const newSocket = io(SOCKET_SERVER_URL, {
      secure: true,//live code
      // transports: ['websocket'],
      auth: {
        token: cookies.get('auth') // Pass the token in the auth object
      }
    });
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  const updateCurrentLocationDriver = (data) => {
    if (socket) {
      socket.emit('updateCurrentLocationDriver', data, (response) => {
        console.log(response)
      });
    }
  }

  useEffect(() => {
    if (socket && user) {
      socket.on('connect', () => {
        console.log('Connected to WebSocket server');
        // updateCurrentLocationDriver({
        //   lat: '30.714532951772725',
        //   lng: '76.71486013604319',
        //   user: '66ac6fbaa8ccf4e4c4059511'
        // });
      });
      socket.on('connect_error', (err) => {
        console.error('Connection error:', err);
        dispatch(toastError(`Socket ${err?.message}`));
      });

      socket.on('newRideRequest', (message) => {
        console.log(message)
        dispatch(toastSuccess(`Ride request has been received from ${message?.passenger?.fullname} `));
      });

      socket.on('newDeliveryRequest', (message) => {
        console.log(message)
        dispatch(toastSuccess(`Delivery request has been received from ${message?.passenger?.fullname} `));
      });

      socket.on('rideCanceledRequest', (message) => {
        console.log(message)
        dispatch(toastSuccess(`Delivery request has been cancelled from ${message?.passenger?.fullname} `));
      });

      socket.on('newErrandRequest', (message) => {
        console.log(message)
        dispatch(toastSuccess(`Errand request has been received from ${message?.passenger?.fullname} `));
      });

      socket.on(`acceptRideNotificationToUser-${user?._id}`, (message) => {
        console.log(message)
        dispatch(toastSuccess(`Ride accepted by ${message?.driver?.fullname} `));
      });
    }
  }, [socket]);

  return (
    <div className="App">
      <Toaster />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Layout />}>
            {FrontendRoute &&
              FrontendRoute.map((item, index) => (
                <Route key={index} path={item.path} element={item.component} />
              ))
            }
          </Route>
          <Route path="/admin" element={<AdminLayout />}>
            {AdminRoute &&
              AdminRoute.map((item, index) => (
                <Route key={index} path={item.path} element={<ProtectedRoute>{item.component}</ProtectedRoute>} />
              ))
            }
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
