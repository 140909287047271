import { PersistGate } from 'redux-persist/es/integration/react';
import store, { persistor } from './redux/store/store';
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/tailwind.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './assets/plugins/simplebar/css/simplebar.css';
import './assets/plugins/perfect-scrollbar/css/perfect-scrollbar.css';
import './assets/plugins/highcharts/css/highcharts.css';
import './assets/plugins/metismenu/css/metisMenu.min.css';
import './assets/css/pace.min.css';
import './assets/css/bootstrap-extended.css';
// import './assets/js/pace.min.js';
import './assets/css/app.css';
import './assets/css/icons.css';
import App from './App';
import { StyledEngineProvider } from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<BrowserRouter>
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<StyledEngineProvider injectFirst>
					<App />
				</StyledEngineProvider>
			</PersistGate>
		</Provider>
	</BrowserRouter>
);

reportWebVitals();
