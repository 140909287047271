import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Cookies } from 'react-cookie';

const ProtectedRoute = (props) => {
    const cookies = new Cookies();
    const navigate = useNavigate();
    const { pathname } = useLocation()
    const cookie = cookies.get('auth');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    // const splitedPath = pathname.split("/")

    const checkUserToken = () => {
        if (cookie === undefined || cookie === null) {
            setIsLoggedIn(false);
            navigate("/login")
        }
        setIsLoggedIn(true);
    }

    useEffect(() => {
        checkUserToken();

        // eslint-disable-next-line
    }, [isLoggedIn]);
    return (
        <Fragment>
            {isLoggedIn ? props.children : null}
        </Fragment>
    );
}
export default ProtectedRoute;